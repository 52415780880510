// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-leadership-js": () => import("./../../../src/templates/Leadership.js" /* webpackChunkName: "component---src-templates-leadership-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-press-releases-js": () => import("./../../../src/templates/PressReleases.js" /* webpackChunkName: "component---src-templates-press-releases-js" */),
  "component---src-templates-pressrelease-js": () => import("./../../../src/templates/Pressrelease.js" /* webpackChunkName: "component---src-templates-pressrelease-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/Story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

